/* src/pages/ProfilePage.css */

.profile-page {
  padding: 2rem;
  background-color: #f0f8ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-heading {
  color: #ff69b4;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px #ffb6c1;
}

.profile-content {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.profile-photo {
  margin-bottom: 1.5rem;
}

.photo-img {
  border-radius: 50%;
  border: 4px solid #ff69b4;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.profile-details,
.profile-education,
.profile-skills,
.profile-hobbies,
.profile-contact {
  background-color: #ffffff;
  border: 2px solid #ffebcc;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.profile-details h3,
.profile-education h3,
.profile-skills h3,
.profile-hobbies h3,
.profile-contact h3 {
  font-size: 1.75rem;
  color: #4b0082;
  margin-bottom: 0.5rem;
}

.detail {
  color: #ff1493;
  font-weight: bold;
}

.bio-text {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.5;
  margin-top: 0.5rem;
}

.profile-skills ul,
.profile-hobbies ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.profile-skills li,
.profile-hobbies li {
  font-size: 1.1rem;
  color: #333;
  margin: 0.5rem 0;
}

.profile-contact a {
  color: #ff69b4;
  text-decoration: none;
  font-weight: bold;
}

.profile-contact a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .profile-heading {
    font-size: 2rem;
  }

  .profile-details h3,
  .profile-education h3,
  .profile-skills h3,
  .profile-hobbies h3,
  .profile-contact h3 {
    font-size: 1.4rem;
  }

  .bio-text {
    font-size: 1rem;
  }

  .profile-skills li,
  .profile-hobbies li {
    font-size: 1rem;
  }
}

.profile-photos {
  width: 100%;
}

.profile-photos h3 {
  text-align: center;
  margin-bottom: 1rem;
}

@media (max-width: 600px) {
  .profile-page {
    padding: 1rem;
  }
}