.home-page {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  color: #333;
}

.header {
  margin-bottom: 20px;
}

.header h1 {
  font-size: 2.5rem;
  color: #fff;
}

.intro {
  font-size: 1.2rem;
  color: #fff;
}

.birthday-message, .birthday-countdown, .birthday-gallery, .birthday-fact-section, .birthday-quiz, .birthday-gift, .birthday-wishes {
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.birthday-message h2, .birthday-countdown h3, .birthday-gallery h3, .birthday-fact-section h3, .birthday-quiz h3, .birthday-gift h3, .birthday-wishes h3 {
  color: #f06c64;
}

.message {
  font-size: 1.1rem;
}

.quiz {
  display: flex;
  flex-direction: column;
}

.quiz-button {
  margin: 10px 0;
  padding: 10px;
  background-color: #f06c64;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.quiz-result {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #f06c64;
}

.final-score {
  margin-top: 20px;
}

.birthday-button {
  padding: 10px 20px;
  background-color: #f06c64;
  color: #fff;
  border: none;
  font-size: 2 rem;
  border-radius: 5px;
  cursor: pointer;
}

.gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.gallery img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.birthday-fact {
  font-size: 1.1rem;
  color: #555;
}

.wishes {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}


.birthday-message h2 {
  color: #ff6f61;
  font-size: 2.5rem;
  text-align: center;
}

.message {
  font-size: 1.4rem;
  line-height: 1.8;
  color: #333;
}

.message p {
  margin: 15px 0;
  text-align: center;
}
