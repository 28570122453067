/* src/LoginPage.css */
.login-body {
  background-color: #f0e5ff;
  font-family: "Comic Sans MS", cursive, sans-serif;
  margin: 0;
  padding: 0;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.login-header {
  font-size: 3rem;
  color: #ff69b4;
  margin-bottom: 2rem;
  text-shadow: 2px 2px #ffa07a;
}

.login-label {
  font-size: 1.5rem;
  color: #4b0082;
  margin-bottom: 1rem;
  display: block;
}

.login-input {
  padding: 0.5rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border: 2px solid #dda0dd;
  border-radius: 5px;
  width: 80%;
}

.login-button {
  background-color: #ff69b4;
  color: white;
  padding: 0.7rem 1.5rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 3px 3px #ffa07a;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #ff1493;
}

.login-input:focus,
.login-button:focus {
  outline: none;
}
