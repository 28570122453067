/* src/Surprise.css */

.surprise-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #fff0f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 2rem auto;
  }
  
  .surprise-heading {
    color: #ff1493;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .dropdown-container {
    margin-bottom: 1rem;
  }
  
  .dropdown {
    font-size: 1rem;
    padding: 0.5rem;
    border: 2px solid #ff69b4;
    border-radius: 5px;
    background-color: #ffffff;
    color: #333;
    cursor: pointer;
  }
  
  .message-box {
    padding: 1rem;
    background-color: #fff;
    border: 2px solid #ff69b4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 1.2rem;
    color: #333;
    max-width: 100%;
    text-align: center;
  }
  
  .media-container {
    margin-top: 1rem;
  }
  
  .media-video,
  .media-audio {
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
  }
  
  @media (max-width: 600px) {
    .surprise-heading {
      font-size: 1.5rem;
    }
  
    .dropdown {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
  
    .message-box {
      font-size: 1rem;
    }
  }
  