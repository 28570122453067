/* src/NavBar.css */

.navbar {
    background-color: #333;
  }
  
  .navbar-title {
    color: white;
    font-size: 1.5rem;
    font-family: 'Comic Sans MS', cursive, sans-serif;
    margin: 0;
  }
  
  .navbar-nav .nav-item .nav-link {
    color: white;
    font-size: 1.2rem;
    transition: color 0.3s;
  }
  
  .navbar-nav .nav-item .nav-link:hover {
    color: #ff69b4;
  }
  
  .navbar-button {
    background-color: #ff69b4;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 1rem;
  }
  
  .navbar-button:hover {
    background-color: #ff1493;
  }
  